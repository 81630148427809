import request from '@/utils/request'
export function dcShoppingmallCreationcenterDetail(params) {
    return request({
        url: 'shoppingMallCommodity/queryIntroduction',
        method: 'get',
        params,
    })
}
export function dcShoppingMallCommodityQueryNews(params) {
    return request({
        url: 'shoppingMallCommodity/queryNews',
        method: 'get',
        params,
    })
}
